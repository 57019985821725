import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles(({palette, spacing}) => ({
  searchIcon: {
    color: palette.text.primary,
    pointerEvents: 'none',
  },
  searchIconWrapper: {
    marginLeft: '0.7em',
    marginRight: spacing(2.5),
    background: 'none',
    color: 'inherit',
    border: 'none',
    padding: '1px 6px',
    borderRadius: 4,
    font: 'inherit',
    cursor: 'pointer',
    '&:hover': {
      background: palette.background.greyLight,
    },
  },
}))

export default useStyles
