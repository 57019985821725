import {func} from 'prop-types'
import React, {forwardRef} from 'react'

import SearchIcon from '@material-ui/icons/Search'

import useStyles from './styles'

const SearchMenuItem = forwardRef(({onClick}, SearchMenuItemRef) => {
  const classes = useStyles()

  return (
    <button
      ref={SearchMenuItemRef}
      className={classes.searchIconWrapper}
      type="button"
      onClick={onClick}
      aria-label="search button"
    >
      <SearchIcon className={classes.searchIcon} />
    </button>
  )
})

SearchMenuItem.propTypes = {
  onClick: func,
}

SearchMenuItem.defaultProps = {
  onClick: null,
}

export default SearchMenuItem
