import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles(
  ({breakpoints, spacing, fontSizes, palette, layout}) => ({
    container: {
      alignItems: 'center',
      background: palette.background.default,
      borderRadius: 4,
      boxShadow: layout.search.modal.boxShadow,
      display: 'block',
      fontFamily: 'CamptonMedium',
      fontSize: fontSizes.description,
      height: 'fit-content',
      left: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: '15 0',
      position: 'absolute',
      right: 0,
      top: 100,
      width: layout.search.modal.width,
      [breakpoints.down('sm')]: {
        top: 60,
        width: 'auto',
      },
    },
    date: {
      fontSize: 16,
      fontFamily: 'CamptonBook',
      color: palette.text.grey,
    },
    departmentTags: {
      color: palette.tags.departments.color,
      backgroundColor: palette.tags.departments.backgroundColor,
      '&:hover': {
        color: palette.tags.departments.color,
        backgroundColor: palette.tags.departments.backgroundColor,
      },
    },
    form: {
      alignItems: 'center',
      fontFamily: 'CamptonMedium',
      background: palette.background.default,
      borderBottom: `1px solid ${palette.grey[300]}`,
      borderRadius: 4,
      display: 'flex',
      padding: spacing(2, 4),
      width: '100%',
      '& svg:last-child': {
        cursor: 'pointer',
      },
      [breakpoints.down('sm')]: {
        padding: spacing(2, '0.8rem'),
      },
    },
    image: {
      marginRight: spacing(4),
      height: layout.search.results.image.height,
      width: layout.search.results.image.width,
      '&::after': {
        borderRadius: spacing(0.5),
      },
      [breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    svgImage: {
      '&::after': {
        borderRadius: spacing(0.5),
      },
      [breakpoints.up('sm')]: {
        marginRight: spacing(4),
        height: layout.search.results.image.height,
        width: layout.search.results.image.width,
      },
      [breakpoints.down('sm')]: {
        textAlign: 'center',
        width: '66%',
        margin: spacing(2, 'auto'),
      },
    },
    industrieTags: {
      color: palette.secondary.main,
      backgroundColor: palette.tags.industries.backgroundColor,
    },
    list: {
      background: palette.background.default,
      listStyleType: 'none',
      '& > a': {
        textDecoration: 'none !important',
        color: 'inherit !important',
      },

      [breakpoints.down('sm')]: {
        paddingLeft: '0.8rem',
      },
    },
    listItem: {
      alignItems: 'center',
      display: 'flex',
      margin: spacing(3, 'auto'),
      [breakpoints.down('sm')]: {
        alignItems: 'start',
        flexDirection: 'column',
      },
    },
    metaDescription: {
      fontFamily: 'CamptonLight',
      fontSize: fontSizes.description,
    },
    noResults: {
      alignItems: 'center',
      display: 'flex',
      height: layout.search.noResults.height,
      justifyContent: 'center',
    },
    partnerTags: {
      color: palette.tertiary.main,
      backgroundColor: palette.tags.partners.backgroundColor,
    },
    results: {
      background: palette.background.default,
      borderRadius: 4,
      height: layout.search.results.height,
      left: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
      overflowY: 'scroll',
      right: 0,
      top: 95,
      width: layout.search.results.width,
      '&::-webkit-scrollbar': {
        width: '0.5em',
      },
      '&::-webkit-scrollbar-track': {
        borderRadius: '10px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'lightgray',
        borderRadius: '10px',
      },
      [breakpoints.down('sm')]: {
        width: 'auto',
      },
    },
    searchInput: {
      border: 'none',
      color: '#050505',
      fontSize: '1em',
      height: layout.search.input.height,
      outline: 'none',
      paddingRight: spacing(1),
      width: layout.search.input.width,
      '&::placeholder': {
        color: '#888',
      },
    },
    searchIcon: {
      color: '#333333',
      fontSize: '1.13em',
      height: '1.25em',
      marginRight: spacing(1),
      pointerEvents: 'none',
      width: '1.25em',
    },
    tag: {
      textTransform: 'none',
      fontSize: fontSizes.small,
      maxHeight: layout.tags.maxHeight,
    },
    tagsContainer: {
      display: 'flex',
      justifyContent: 'left',
      flexWrap: 'wrap',
      marginTop: spacing(2),
      '& Button': {
        margin: 3,
      },
      [breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
    },
    titleResults: {
      paddingLeft: spacing(4),
      paddingTop: spacing(4),
      [breakpoints.down('sm')]: {
        paddingLeft: '0.8rem',
      },
    },
    type: {
      margin: 0,
      color: palette.primary.main,
      fontFamily: 'CamptonSemiBold',
      fontSize: fontSizes.description,
    },
  }),
)

export default useStyles
